import React, { useState, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import {
  CssBaseline,
  Grid,
  Container,
  Card,
  CardContent,
  FormControl,
  TextField,
  Slider,
  Box,
  Skeleton,
} from "@mui/material";
import { SpaceDashboard, Cancel } from "@mui/icons-material";
// reactstrap components
import { Row, Col, Button } from "reactstrap";
import axios from "axios";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import Paginator from "./PaginatorSearch";
import LoadTypeOperation from "components/Services/LoadTypeOperation";
import LoadTypeProperty from "components/Services/LoadTypeProperty";
import LoadStates from "components/Services/LoadStates";
import LoadCities from "components/Services/LoadCities";

import {
  TitleTextPage,
  ParagraphTextPage,
  blackColor,
} from "components/cardBodyStyle";

const RealEstateSearch = () => {
  const [signUp, setSignUp] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [openLoader, setOpenLoader] = React.useState(true);
  const [priceRange, setPriceRange] = useState([0, 300000]); // Rango de precio inicial

  const { urlServices } = React.useContext(UrlServicesContext);

  const [values, setValues] = React.useState({
    typeOperation: "",
    typeProperty: "",
    typeOperationData: "",
    typePropertyData: "",
    states: "",
    statesData: "",
    city: "",
    cityData: "",
    disabledCity: true,
  });

  useEffect(() => {
    fetchData();
    document.body.classList.toggle("index-page");
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${urlServices}publications/list/page`);

      const transformedRows = response.data.map((data) => ({
        ...data,
        images: parseJsonArray(data.images),
        services: parseJsonArray(data.services),
        details: parseJsonArray(data.details),
      }));

      setRows(transformedRows);
      setOpenLoader(false);
    } catch (error) {
      setOpenLoader(false);
    }
  };

  // Función para parsear cadenas JSON y asegurar que sean arrays
  const parseJsonArray = (jsonString) => {
    try {
      const parsed = JSON.parse(jsonString);
      return Array.isArray(parsed) ? parsed : [];
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [];
    }
  };

  const changeTypeOperation = (data) => {
    setValues({
      ...values,
      typeOperation: data !== null ? data.id : "",
      typeOperationData: data !== null ? data : "",
    });
  };

  const cancelTypeOperationChange = () => {
    setValues({
      ...values,
      typeOperation: "",
      typeOperationData: "",
    });
  };

  const changeTypeProperty = (data) => {
    setValues({
      ...values,
      typeProperty: data !== null ? data.id : "",
      typePropertyData: data !== null ? data : "",
    });
  };

  const cancelTypePropertyChange = (event) => {
    setValues({
      ...values,
      typeProperty: "",
      typePropertyData: "",
    });
  };

  const changeStates = (data) => {
    setValues({
      ...values,
      states: data !== null ? data.id : "",
      statesData: data !== null ? data : "",
      city: "",
      cityData: "",
      disabledCity: data !== null ? false : true,
    });
  };

  const cancelStatesChange = () => {
    setValues({
      ...values,
      states: "",
      statesData: "",
      city: "",
      cityData: "",
      disabledCity: true,
    });
  };

  const changeCity = (data) => {
    setValues({
      ...values,
      city: data !== null ? data.id : "",
      cityData: data !== null ? data : "",
    });
  };
  const cancelCityChange = () => {
    setValues({
      ...values,
      city: "",
      cityData: "",
    });
  };

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  // Filtrar propiedades según los filtros seleccionados
  const filteredProperties = rows.filter((property) => {
    return (
      (values.states === "" || property.statesId === values.states) &&
      (values.city === "" || property.cityId === values.city) &&
      (values.typeOperation === "" ||
        property.typeOperationId === values.typeOperation) &&
      (values.typeProperty === "" ||
        property.typePropertyId === values.typeProperty) &&
      property.price >= priceRange[0] &&
      property.price <= priceRange[1]
    );
  });

  const returnSignUpClose = (data) => {
    setSignUp(data);
  };

  return (
    <>
      <IndexNavbar signUp={signUp} signUpClose={returnSignUpClose} />
      <div className="wrapper">
        <div
          className="section section-tabs"
          style={{
            background:
              "linear-gradient(#ccc, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff ,#ffffff)",
            paddingTop: 160,
          }}
        >
          <CssBaseline />
          <Container>
            <Grid container spacing={3}>
              {/* Filtros */}
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent>
                    <TitleTextPage style={{ fontSize: 18 }}>
                      Filtros
                    </TitleTextPage>
                    {/* Filtro por texto */}
                    {/*<TextField
                      label="Buscar por título"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />*/}
                    {/* Filtro por provincia */}
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ m: "2px 0 2px 0" }}
                    >
                      <LoadTypeOperation
                        value={values.typeOperationData}
                        refresh={changeTypeOperation}
                      />
                      {values.typeOperation !== "" ? (
                        <div
                          style={{ textAlign: "right", cursor: "pointer" }}
                          onClick={cancelTypeOperationChange}
                        >
                          <Cancel sx={{ fontSize: 16, color: "red" }} />{" "}
                          <span style={{ color: "red", fontSize: 10 }}>
                            Eliminar selección
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    {/* Filtro por ciudad */}
                    <FormControl fullWidth size="small" sx={{ m: "3px 0" }}>
                      <LoadTypeProperty
                        value={values.typePropertyData}
                        refresh={changeTypeProperty}
                      />
                      {values.typeProperty !== "" ? (
                        <div
                          style={{ textAlign: "right", cursor: "pointer" }}
                          onClick={cancelTypePropertyChange}
                        >
                          <Cancel sx={{ fontSize: 16, color: "red" }} />{" "}
                          <span style={{ color: "red", fontSize: 10 }}>
                            Eliminar selección
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    {/* Filtro por Tipo de inmueble */}
                    <FormControl fullWidth size="small" sx={{ m: "3px 0" }}>
                      <LoadStates
                        value={values.statesData}
                        refresh={changeStates}
                        idCountries={45}
                      />
                      {values.states !== "" ? (
                        <div
                          style={{ textAlign: "right", cursor: "pointer" }}
                          onClick={cancelStatesChange}
                        >
                          <Cancel sx={{ fontSize: 16, color: "red" }} />{" "}
                          <span style={{ color: "red", fontSize: 10 }}>
                            Eliminar selección
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    {/* Filtro por Transacción */}
                    <FormControl fullWidth size="small" sx={{ m: "3px 0" }}>
                      <LoadCities
                        value={values.cityData}
                        refresh={changeCity}
                        disabled={values.disabledCity}
                        idStates={values.states}
                      />
                      {values.city !== "" ? (
                        <div
                          style={{ textAlign: "right", cursor: "pointer" }}
                          onClick={cancelCityChange}
                        >
                          <Cancel sx={{ fontSize: 16, color: "red" }} />{" "}
                          <span style={{ color: "red", fontSize: 10 }}>
                            Eliminar selección
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    <Box sx={{ mt: 1 }}>
                      <ParagraphTextPage style={{ fontSize: 14 }}>
                        Rango de Precio
                      </ParagraphTextPage>
                      <Slider
                        value={priceRange}
                        onChange={handlePriceRangeChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={300000}
                        step={10000}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Card sx={{ mt: 5, display: { md: "block", xs: "none" } }}>
                  <CardContent>
                    <Box
                      component="form"
                      // onSubmit={handleSubmit}
                      sx={{ maxWidth: 400 }}
                    >
                      <TitleTextPage style={{ fontSize: 28 }}>
                        Más información
                      </TitleTextPage>
                      <ParagraphTextPage>
                        Un asesor se podrá en contacto contigo.
                      </ParagraphTextPage>
                      <Row>
                        <Col className="ml-auto mr-auto" md="12" xs="12">
                          <TextField
                            label="Correo electrónico"
                            placeholder="Correo electrónico"
                            variant="outlined"
                            sx={{ width: "100%", mt: 2 }}
                            // value={searchText}
                            // onChange={handleSearchChange}
                            autoComplete="off"
                          />
                        </Col>
                        <Col className="ml-auto mr-auto" md="12" xs="12">
                          <TextField
                            label="Teléfono celular"
                            placeholder="Teléfono celular"
                            variant="outlined"
                            sx={{ width: "100%", mt: 2 }}
                            autoComplete="off"
                          />
                        </Col>
                        <Col className="ml-auto mr-auto" md="12" xs="12">
                          <TextField
                            label="Asunto"
                            variant="outlined"
                            placeholder="Asunto"
                            sx={{ width: "100%", mt: 2 }}
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                      <center>
                        <Button
                          //href="/information"
                          className="btn-round"
                          color="info"
                          size="md"
                          style={{
                            background: "#000000",
                            color: "#ffffff",
                            letterSpacing: ".011em",
                            fontFamily: "sans-serif",
                            marginTop: 15,
                          }}
                        >
                          <b>Enviar</b>
                        </Button>
                      </center>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              {/* Resultados de la búsqueda paginados */}
              <Grid item xs={12} md={9}>
                <TitleTextPage style={{ marginBottom: 30, fontSize: 32 }}>
                  <SpaceDashboard /> Inmuebles publicados{" "}
                  <span
                    style={{
                      fontSize: 12,
                      float: "right",
                      padding: "5px 10px",
                      border: `1px solid #e9ecef`,
                      color: blackColor,
                      background: "#ffffff",
                      borderRadius: 50,
                    }}
                  >
                    Total {filteredProperties.length} registro
                    {filteredProperties.length > 1 ? "s" : ""}
                  </span>
                </TitleTextPage>
                {openLoader ? (
                  <div className="header-filter">
                    <Row>
                      {[...Array(9)].map((_, idx) => (
                        <Col
                          key={idx}
                          className="ml-auto mr-auto"
                          md="6"
                          xl="4"
                          style={{ marginTop: 30 }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={250}
                            height={150}
                            animation="wave"
                          />
                          <Skeleton
                            variant="rectangular"
                            width={230}
                            height={15}
                            animation="wave"
                            sx={{ mt: 1 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={250}
                            height={15}
                            animation="wave"
                            sx={{ mt: 1 }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : (
                  <Paginator properties={filteredProperties} />
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default RealEstateSearch;

import React, { useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import axios from "axios";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Skeleton } from "@mui/material";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

// sections for this page/view
import Section1 from "views/IndexSections/Section1.js";
import Section2 from "views/IndexSections/Section2.js";
import Section3 from "views/IndexSections/Section3.js";
import Section4 from "views/IndexSections/Section4.js";

export default function Index() {
  const [signUp, setSignUp] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [openLoader, setOpenLoader] = React.useState(true);

  const { urlServices } = React.useContext(UrlServicesContext);

  useEffect(() => {
    fetchData();
    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);

  const fetchData = async () => {
    const valueData = {
      values: [4, 5, 6, 7],
    };

    try {
      const response = await axios.post(
        `${urlServices}post-templates/sections/pages`,
        valueData
      );

      const transformedRows = response.data.map((data) => ({
        ...data,
        images: parseJsonArray(data.images),
        buttons: parseJsonArray(data.buttons),
      }));

      setRows(transformedRows);
      setOpenLoader(false);
    } catch (error) {
      setOpenLoader(false);
    }
  };

  // Función para parsear cadenas JSON y asegurar que sean arrays
  const parseJsonArray = (jsonString) => {
    try {
      const parsed = JSON.parse(jsonString);
      return Array.isArray(parsed) ? parsed : [];
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [];
    }
  };

  const returnSignUp = (data) => {
    setSignUp(data);
  };

  const returnSignUpClose = (data) => {
    setSignUp(data);
  };

  return (
    <>
      <IndexNavbar signUp={signUp} signUpClose={returnSignUpClose} />
      <div className="wrapper">
        {/*AQUI HEADER*/}
        <div className="main">
          {openLoader ? (
            <Container
              style={{ padding: "180px 0px 20px 0px", background: "#FFFFFF" }}
            >
              <div className="header-filter">
                <Row>
                  <Col className="ml-auto mr-auto" md="10" xl="6">
                    <Skeleton
                      variant="rectangular"
                      width={550}
                      height={400}
                      animation="wave"
                    />
                  </Col>
                  <Col
                    className="ml-auto mr-auto"
                    md="10"
                    xl="6"
                    style={{ padding: "40px 0 40px 40px" }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={530}
                      height={310}
                      animation="wave"
                    />
                    <Row>
                      <Col
                        className="ml-auto mr-auto"
                        md="10"
                        xl="6"
                        style={{ padding: "40px 0 40px 40px" }}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={50}
                          animation="wave"
                        />
                      </Col>
                      <Col
                        className="ml-auto mr-auto"
                        md="10"
                        xl="6"
                        style={{ padding: "40px 0 40px 40px" }}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={50}
                          animation="wave"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            <>
              <Section1
                signUp={returnSignUp}
                data={rows}
                urlServices={urlServices}
              />
              <Section2 data={rows} urlServices={urlServices} />
              <Section3 data={rows} urlServices={urlServices} />
              <Section4 data={rows} urlServices={urlServices} />
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}

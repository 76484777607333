import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@mui/styles";
import { ArrowRightAlt } from "@mui/icons-material";
// core components
import { Stack, Grid } from "@mui/material";

import BarChartPropertiesCities from "views/Graphs/BarChartPropertiesCities";
import PieChartPropertiesType from "views/Graphs/PieChartPropertiesType";

import {
  dashboardStyle,
  ButtonExit,
  TitleTextPage,
} from "components/dashboardStyle.js";

const useStyles = makeStyles(dashboardStyle);

export default function DashboardCustomers() {
  const classes = useStyles();

  const [buttonProperties, setButtonProperties] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (buttonProperties) {
    return <Navigate to="/account/properties" />;
  }

  return (
    <div>
      <div className={classes.cardDashboardTop}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Stack direction="row" spacing={2}>
              <div style={{ fontSize: 16, color: "#FFF" }}>
                Bienvenido
                <br></br>
                <b style={{ fontSize: 22 }}>A tu dashboard administrativo</b>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className={classes.carDashboard}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} sx={{ marginBottom: 3 }}>
                <BarChartPropertiesCities />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.carDashboard}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} sx={{ marginBottom: 3 }}>
                <PieChartPropertiesType />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.carDashboard}
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TitleTextPage style={{ fontSize: 22, marginRight: 45 }}>
              Registra e impulsa tus inmuebles
            </TitleTextPage>{" "}
            <ButtonExit
              endIcon={<ArrowRightAlt />}
              onClick={() => setButtonProperties(true)}
            >
              IR AL MÓDULO DE INMUEBLES
            </ButtonExit>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

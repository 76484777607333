import React, { useState, useEffect, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Navigate } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@mui/styles";
import { ArrowRightAlt, MapsHomeWork } from "@mui/icons-material";
// core components
import { Stack, Grid, Alert } from "@mui/material";
import axios from "axios";

import BarChartPropertiesCitiesAdmin from "views/Graphs/BarChartPropertiesCitiesAdmin";
import PieChartPropertiesTypeAdmin from "views/Graphs/PieChartPropertiesTypeAdmin";
import PieChartProfilesAdmin from "views/Graphs/PieChartProfilesAdmin";

import {
  dashboardStyle,
  ButtonExit,
  ColorLinearProgress,
} from "components/dashboardStyle.js";
import { blackColor } from "components/material-dashboard-react";

const useStyles = makeStyles(dashboardStyle);

export default function Dashboard() {
  const classes = useStyles();

  const [returnLogin, setReturnLogin] = React.useState(false);
  const [error, setError] = useState(false);
  const [mensageSystem, setMensageSystem] = useState("");
  const [buttonUsers, setButtonUsers] = useState(false);
  const [buttonProperties, setButtonProperties] = useState(false);
  const [countProject, setCountProject] = useState(0);
  const [countPublication, setCountPublication] = useState(0);

  const { urlServices, UserAuth } = useContext(UrlServicesContext);
  let keyAuthorization = UserAuth.Session;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${urlServices}publications/reports-dashboard`,
          {
            headers: {
              Authorization: "Bearer " + keyAuthorization,
            },
          }
        );

        setCountProject(response.data.projectPost.length);
        setCountPublication(response.data.publications.length);
      } catch (error) {
        if (error.response?.status === 401) {
          setMensageSystem(
            "La sesión ha expirado, vuelva a iniciar sesión ..."
          );
          setError(true);
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 4000);
        } else if (error.response?.status === 500) {
          setMensageSystem("Error en la consulta con el servidor.");
          setError(true);
        } else {
          console.error("Error en la solicitud:", error);
          setMensageSystem("Error al realizar la solicitud.");
          setError(true);
        }
      }
    };

    fetchData();
  }, [urlServices, UserAuth.id, keyAuthorization]);

  if (buttonUsers) {
    return <Navigate to="/account/users" />;
  }

  if (buttonProperties) {
    return <Navigate to="/account/properties" />;
  }

  if (returnLogin) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <div className={classes.cardDashboardTop}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Stack direction="row" spacing={2}>
              <div style={{ fontSize: 16, color: "#FFF" }}>
                Bienvenido administrador
                <br></br>
                <b style={{ fontSize: 22 }}>A tu dashboard</b>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} md={12}>
          {error && <Alert severity="error">{mensageSystem}</Alert>}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: countPublication > 0 ? "block" : " none" }}
        >
          <Alert
            severity="warning"
            icon={false}
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Hay publicaciones pendiente por ACTIVAR.{" "}
            <ButtonExit onClick={() => setButtonProperties(true)}>
              Ver publicaciones
            </ButtonExit>
          </Alert>
          <ColorLinearProgress />
        </Grid>
        <Grid item xs={12} md={9}>
          <div className={classes.carDashboard}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <BarChartPropertiesCitiesAdmin />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.carDashboard}>
            <Grid container>
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <span
                  className={classes.cardTitleBlack}
                  style={{ fontSize: 14 }}
                >
                  Proyectos registrados
                </span>
              </Grid>
              {error && (
                <Grid item xs={12} md={12}>
                  <Alert severity="error">Error en la consulta</Alert>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <center>
                  <div
                    style={{
                      background: "#F1F1F1",
                      color: blackColor,
                      padding: "35px 8px",
                      borderRadius: "50%",
                      fontSize: 22,
                      margin: "60px 20px 0 20px",
                      width: 110,
                      fontWeight: "bold",
                    }}
                  >
                    {countProject}
                  </div>
                  <MapsHomeWork
                    sx={{ color: blackColor, margin: "40px 0", fontSize: 36 }}
                  />
                </center>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.carDashboard} style={{ marginTop: "-1px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <PieChartPropertiesTypeAdmin />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.carDashboard} style={{ marginTop: "-1px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <PieChartProfilesAdmin />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.carDashboard}
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonExit
              endIcon={<ArrowRightAlt />}
              onClick={() => setButtonProperties(true)}
            >
              IR AL MÓDULO DE INMUEBLES
            </ButtonExit>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.carDashboard}
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonExit
              endIcon={<ArrowRightAlt />}
              onClick={() => setButtonUsers(true)}
            >
              IR AL MÓDULO DE UAURIOS
            </ButtonExit>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { UrlServicesContext } from "components/UrlServicesContext";

// reactstrap components
import { makeStyles } from "@mui/styles";
import { Button } from "reactstrap";
import GoogleLogin from "react-google-login";
import { Visibility, VisibilityOff, Google } from "@mui/icons-material";
import axios from "axios";
import {
  Grid,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Alert,
  Snackbar,
  Slide,
  Box,
} from "@mui/material";

import {
  TitleTextPage,
  ParagraphTextPage,
  blackColor,
  cardBodyStyle,
  CircularProgressTheme,
} from "components/cardBodyStyle";

import PasswordRecovery from "../Dialog/PasswordRecovery";

const useStyles = makeStyles(cardBodyStyle);

export default function Login(props) {
  const classes = useStyles();

  const [loaderLogin, setLoaderLogin] = useState(false);
  const [passwordRecovery, setpasswordRecovery] = useState(false);

  const [values, setValues] = useState({
    mail: "",
    password: "",
    newPassword: "",
    userspass: "",
    showPassword: false,
    errorMail: false,
    errorPassword: false,
  });

  const { urlServices, LoginAuth, keyLoginGoogle } =
    useContext(UrlServicesContext);

  const [alert, setAlert] = useState({
    openAlert: false,
    mensaje_error: "",
    loggedIn: localStorage.getItem("Session") === null ? false : true,
  });

  const handleChange = (prop) => (event) => {
    setAlert({
      ...alert,
      openAlert: false,
    });
    setValues({
      ...values,
      [prop]: event.target.value,
      errorMail: false,
      errorPassword: false,
    });
  };

  const submitFormLogin = () => (event) => {
    event.preventDefault();

    if (values.mail.length === 0) {
      setValues({
        ...values,
        errorMail: true,
      });
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo Correo electrónico es obligatorio.",
      });
    } else if (values.password.length === 0) {
      setValues({
        ...values,
        errorPassword: true,
      });
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo contraseña es obligatorio.",
      });
    } else {
      setLoaderLogin(true);

      const data = {
        email: values.mail.trim(),
        password: values.password,
      };

      LoginServices(data);
    }
  };

  const LoginServices = (data) => {
    axios
      .post(`${urlServices}users/login`, data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.user.status === 0) {
            setLoaderLogin(false);
            setAlert({
              ...alert,
              openAlert: true,
              mensaje_error: "Usuario deshabilitado del sistema.",
            });
          } else {
            LoginAuth(response.data); // SAVE SESSION
            setTimeout(() => {
              redirectProgile(response.data.user.profileId);
            }, 1000);
          }
        }
      })
      .catch((e) => {
        if (e.response === undefined) {
          setLoaderLogin(false);
          setAlert({
            ...alert,
            openAlert: true,
            mensaje_error: "Error de conexión, intente más tarde.",
          });
        } else if (e.response.status === 404) {
          setLoaderLogin(false);
          setValues({
            ...values,
            errorMail: true,
          });
          setAlert({
            ...alert,
            openAlert: true,
            mensaje_error: e.response.data.message,
          });
        } else if (e.response.status === 401) {
          setLoaderLogin(false);
          setValues({
            ...values,
            errorPassword: true,
          });
          setAlert({
            ...alert,
            openAlert: true,
            mensaje_error: e.response.data.message,
          });
        }
      });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ openAlert: false });
  };

  const handleOpenPasswordRecovery = () => {
    setpasswordRecovery(true);
  };

  const handleClosePasswordRecovery = () => {
    setpasswordRecovery(false);
  };

  const redirectProgile = (profile) => {
    localStorage.setItem("controller", "/account");
    localStorage.setItem(
      "controllerRouter",
      `/account/${profile !== 1 ? `dashboard-customer` : `dashboard`}`
    );

    setAlert({ ...alert, loggedIn: true });
  };

  const responseGoogle = (dataGoogle) => {
    if (dataGoogle.profileObj) {
      setLoaderLogin(true);

      setValues({
        ...values,
        mail: dataGoogle.profileObj.email,
        password: "123456789",
      });

      const data = {
        email: dataGoogle.profileObj.email,
        password: 99,
      };

      LoginServices(data);
    }
  };

  if (alert.loggedIn) {
    return (
      <Navigate
        from={localStorage.getItem("controller")}
        to={localStorage.getItem("controllerRouter")}
      />
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12} sx={{ marginBottom: 2 }}>
        <TitleTextPage style={{ fontSize: 22 }}>
          ¡Hola, nos alegra volver a verte!
        </TitleTextPage>
        <ParagraphTextPage style={{ fontSize: 16 }}>
          Ingresa a tu cuenta y accede a contactar o a registrar tus
          publicaciones.
        </ParagraphTextPage>
      </Grid>
      <Grid item xs={12} md={12}>
        <form onSubmit={submitFormLogin}>
          <TextField
            id="outlined-basic"
            label="Correo electrónico"
            variant="outlined"
            value={values.mail}
            onChange={handleChange("mail")}
            error={values.errorMail}
            type="email"
            autoComplete="off"
            margin="dense"
            fullWidth
          />
          <FormControl variant="outlined" fullWidth sx={{ m: "15px 0" }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Contraseña
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              error={values.errorPassword}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <Visibility
                        fontSize="small"
                        className={classes.iconInput}
                      />
                    ) : (
                      <VisibilityOff
                        fontSize="small"
                        className={classes.iconInput}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={80}
              label="Contraseña"
            />
          </FormControl>
          <a
            onClick={handleOpenPasswordRecovery}
            style={{
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            <ParagraphTextPage
              style={{
                color: blackColor,
                fontSize: 12,
              }}
            >
              ¿Contraseña olvidada?
            </ParagraphTextPage>
          </a>
          <center>
            <GoogleLogin
              clientId={keyLoginGoogle}
              // buttonText="Iniciar sesión con google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <Box
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  //disabled={true}
                  className={classes.btnGoogle}
                >
                  <Google
                    style={{
                      fontSize: 18,
                      color: blackColor,
                      marginRight: 10,
                    }}
                  />
                  INGRESAR CON GOOGLE
                </Box>
              )}
            />
          </center>
          <center>
            {loaderLogin ? (
              <CircularProgressTheme style={{ marginTop: 40 }} />
            ) : (
              <Button
                className="btn-round"
                color="info"
                size="lg"
                style={{
                  marginTop: 40,
                  marginLeft: 10,
                  background: blackColor,
                  color: "#ffffff",
                  letterSpacing: ".011em",
                  fontFamily: "sans-serif",
                }}
                onClick={submitFormLogin()}
                type="submit"
              >
                <b>Iniciar sesión</b>
              </Button>
            )}
          </center>
        </form>
      </Grid>
      {alert.openAlert && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseAlert}
        >
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              elevation={6}
              variant="filled"
            >
              {alert.mensaje_error}
            </Alert>
          </Slide>
        </Snackbar>
      )}

      {passwordRecovery && (
        <PasswordRecovery
          open={passwordRecovery}
          exit={handleClosePasswordRecovery}
        />
      )}
    </Grid>
  );
}

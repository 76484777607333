import React, { useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import ExitToApp from "@mui/icons-material/ExitToApp";

// core components
import { Stack, Avatar, Grid, Button } from "@mui/material";

import PopupLogout from "views/Dialog/PopupLogout";

import mujerAvatar from "assets/img/undraw_mujer.png";
import hombreAvatar from "assets/img/undraw_hombre.png";

import { blackColor } from "components/headerLinksStyle.js";

export default function AdminNavbarLinks() {
  const [popupLogout, setpopupLogout] = React.useState(false);

  const { UserAuth } = useContext(UrlServicesContext);

  const picture =
    UserAuth.avatar === null
      ? UserAuth.gender === 1
        ? hombreAvatar
        : mujerAvatar
      : UserAuth.avatar;

  const handleOpenpopupLogout = () => {
    setpopupLogout(true);
  };

  const handleClosepopupLogout = () => {
    setpopupLogout(false);
  };

  return (
    <div>
      <Grid container style={{ float: "right" }}>
        <Grid xs={12} md={12}>
          <Stack direction="row" spacing={2}>
            <Avatar
              src={picture}
              style={{ marginTop: 10, width: 40, height: 40 }}
            />
            <div style={{ marginTop: 25, fontSize: 10, color: blackColor }}>
              <b>
                {UserAuth.allname}
                {/*<div style={{ marginTop: "-8px", color: "gray" }}>
                  {UserAuth.profile_name}
                </div>*/}
              </b>
            </div>
            <Button aria-haspopup="true" onClick={handleOpenpopupLogout}>
              <ExitToApp
                style={{
                  fontSize: 22,
                  color: "#000",
                  marginLeft: "-40px",
                  marginTop: 10,
                }}
              />
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {popupLogout ? (
        <PopupLogout open={popupLogout} exit={handleClosepopupLogout} />
      ) : (
        ""
      )}
    </div>
  );
}
